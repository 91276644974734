import { postTranOut, postTranIn } from '@/api';
export default {
  data() {
    return {
      dialogMsg: "",
      dialogType: 0,
      showCenter: false
    };
  },
  methods: {
    openDialog(value) {
      this.dialogType = value;
      if (value === 0) {
        this.dialogMsg = "您确定把离岸资产转入到离岸账户吗？";
      } else {
        this.dialogMsg = "您确定把离岸账户转出到内地银行卡账户吗？";
      }
      this.showCenter = true;
    },
    postDialog() {
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      if (this.dialogType == 0) {
        postTranIn().then(res => {
          this.$closeToast();
          this.$toast(res.msg);
          this.showCenter = false;
        }).catch(() => {
          this.showCenter = false;
        });
      } else {
        postTranOut().then(res => {
          this.$closeToast();
          this.$toast(res.msg);
          this.showCenter = false;
        }).catch(() => {
          this.showCenter = false;
        });
      }
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  }
};